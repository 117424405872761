import { useState, useContext } from "react";
import * as Yup from "yup";
import { postData, getData } from "./APIServices"; // Import your custom methods
import Cookies from 'js-cookie';
import config from "../config";

const REACT_APP_API_BASE_URL = config.APP_API_BASE_URL;

const AddHotspot = ({ onAdd, onClose , adminID , setHotspots , hotspots }) => {
  const [newHotspot, setNewHotspot] = useState({
    hotspotName: "",
    //routerName: "",
    secretRadius: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewHotspot((prev) => ({ ...prev, [name]: value }));
  };

  const validationSchema = Yup.object().shape({

    hotspotName: Yup.string().required("Le nom du hotspot est requis"),

    //routerName: Yup.string().required("Le nom du routeur est requis"),

    secretRadius: Yup.string().required("Le secret Radius est requis"),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    //add hospot in UI
    try {
      e.preventDefault();
      if (!adminID){alert("AdminID is not defined") 
        return 
      }

      await validationSchema.validate(newHotspot, { abortEarly: false });
      // Add your login logic here
      
      const token = Cookies.get('token');
      const response = await postData(`${REACT_APP_API_BASE_URL}/hotspot/${adminID}` ,
        newHotspot , token
      );
      console.log("hosptoss",hotspots)
      console.log("new",newHotspot)
      console.log("Login submitted:", response);
      const id = response.data.hospotID
      setHotspots([...hotspots, { ...newHotspot, id: id }]);
      
      onAdd(newHotspot);
      onClose();
      alert("Hospot added Sucessful")
      // console.log(response);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        alert(error.errors[0]);
      } else {
        console.log("ici je console l'erreur", error);
        alert(error);
      }
    }
    
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-slate-700 p-6 rounded-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Add New Hotspot</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Hotspot Name
            </label>
            <input
              type="text"
              name="hotspotName"
              value={newHotspot.hotspotName}
              onChange={handleChange}
              className="w-full p-2 bg-slate-600 rounded"
              required
            />
          </div>
          {/* <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Router Name
            </label>
            <input
              type="text"
              name="routerName"
              value={newHotspot.routerName}
              onChange={handleChange}
              className="w-full p-2 bg-slate-600 rounded"
              required
            />
          </div> */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Secret Radius
            </label>
            <input
              type="text"
              name="secretRadius"
              value={newHotspot.secretRadius}
              onChange={handleChange}
              className="w-full p-2 bg-slate-600 rounded"
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 rounded hover:bg-blue-600"
            >
              Add Hotspot
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddHotspot;
