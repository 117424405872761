import { useState, useContext } from "react";
import * as Yup from "yup";
import axios from "axios";
import { postData } from "./APIServices"; // Import your custom methods
import Cookies from 'js-cookie';
import LoadingSpinner from "./LoadingSpinner";
import config from "../config";


//const planNames = ["Plan_200", "Plan_300"];

const AddVouchers = ({ setIsAddVouchersModalOpen, dataPlanNames , hotspotId , vouchersGroups,setVouchersGroups}) => {


  const REACT_APP_API_BASE_URL = config.APP_API_BASE_URL;

  const [isLoading, setIsLoading] = useState(false);

  const [voucherGroup, setVoucherGroup] = useState({
    //
    planNameId: "",
    planName: '',
    description: "",
    nbrOfTicket: 10,
    prefixOnTicket: "",
    isUsernameEqualLogin: false, // New state for checkbox
  });

  const EmptyStringToUndefined = (value, originalValue) => 
    String(originalValue).trim() === '' ? undefined : value

  //this basically get data from from and do setVoucher to rerender state
  const handleInputChange = (e) => {
    const { name, type, value, checked, selectedIndex  } = e.target;

    let inputValue = "";

    if (type === "checkbox") {
      inputValue = checked;
    } else {
      inputValue = value;
    }

    if (name === "planName") {
      //this basically get the id from data-id attribute option and you get empty when the data-id not exist
      const selectedPlanId = e.target[selectedIndex].getAttribute('data-id')
      setVoucherGroup((prevData) => ({
        ...prevData,
        planNameId: selectedPlanId || "", // Capture the id
        planName: value, // Capture the plan name
      }));
    } else {
      setVoucherGroup((prevData) => ({
        ...prevData,
        [name]: inputValue,
      }));
    }
    //console.log(dataPlan)
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string(),

    //planName: Yup.string().required("Le type de forfait est requis"), // Required field with a French error message

    planNameId:Yup.number().transform(
      EmptyStringToUndefined
    )
    .required("Le Plan Name Id est requis") // Required field
    .integer("Le Plan Name Id est un entier"),

    nbrOfTicket: Yup.number()
      .required("Le nombre de tickets est requis") // Required field
      .integer("Le nombre de tickets doit être un nombre entier")
      .min(1, "Le nombre de tickets doit être au moins 1") // Must be an integer
      .max(200, "Le nombre de tickets max est de 200"), // Must be an integer

    prefixOnTicket: Yup.string().max(
      30,
      "Le préfixe ne doit pas dépasser 30 caractères"
    ), // Max 30 characters
  });

  const addVoucherGroup = async (voucherGroup) => {
    "";
    //setIsLoading(true);
    const token = Cookies.get('token');
    const response = await postData(`${REACT_APP_API_BASE_URL}/voucherGroup/${hotspotId}` ,
      voucherGroup , token
    ); 
    return response.data
  };

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    try {
      console.log(voucherGroup)
      await validationSchema.validate(voucherGroup, { abortEarly: false });
      // If validation passes, proceed with submission
      const response = await addVoucherGroup(voucherGroup);
      const groupID = response['id']
      //set the a new addGroup that going to be used to update UI
      setVouchersGroups()
      setVouchersGroups([{...voucherGroup , id:groupID} ,...vouchersGroups ])
      setIsAddVouchersModalOpen(false)
    } catch (error) {
      // Handle validation errors
      console.log(error)
      if (error instanceof Yup.ValidationError) {
        alert(error.errors[0]);
      } else {
        console.log("ici je console l'erreur" , error)
        alert(error);
      }
      return;
    }finally {
      setIsLoading(false);
    }
    //you can first do all checking here and alert them
    console.log(voucherGroup);
    //send data to server
  };

  return (
    <div>
      <div className="fixed inset-0 top-10 bottom-10 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 ">
        <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
          {/* Close Button */}
          <button
            className="absolute text-2xl top-8 right-2 text-red-600 hover:text-gray-900"
            onClick={() => setIsAddVouchersModalOpen(false)}
          >
            X
          </button>

          {/* Form Title */}
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            Ajouter un nouveau Groupe de Tickets
          </h2>

          {/* Edit Form */}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">Prefixe sur Ticket </label>
              <input
                type="text"
                name="prefixOnTicket"
                placeholder="Prefixe sur le Ticket"
                value={voucherGroup.prefixOnTicket}
                onChange={handleInputChange}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Description</label>
              <input
                type="text"
                name="description"
                placeholder="description"
                value={voucherGroup.description}
                onChange={handleInputChange}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            {/* Checkbox for "Username = Login?" */}
            <div className="mb-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="isUsernameEqualLogin"
                  checked={voucherGroup.isUsernameEqualLogin} // Use state from voucher
                  onChange={handleInputChange}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">
                  Cocher si Username = Login?
                </span>
              </label>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Type de Forfait</label>
              <select
                name="planName"
                value={voucherGroup.planName}
                onChange={handleInputChange}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              >
                {/* Add your options here */}
                <option value="">Sélectionnez un type</option>
                {dataPlanNames.map((plan) => (
                  <option key={plan.id} value={plan.planName} data-id={plan.id}>
                    {plan.planName}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Nombre de Tickets</label>
              <input
                type="number"
                name="nbrOfTicket"
                value={voucherGroup.nbrOfTicket}
                onChange={handleInputChange}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
              >
                Validate
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* The loading spinner will overlay the entire screen */}
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default AddVouchers;
