import axios from 'axios';



export const putData = async (url, payload , token) => {
  "";
  //setIsLoading(true);
  try {

    // Prepare headers
    const headers = {};

    // Check if the token is defined and add it to the headers
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.put(url, {payload},
      {
            headers: headers,
        });
    //console.log('Response data:', response.data);
    return response
    //setResponse(result.data.message);
  } catch (error) {
    console.error(error);
    const res = error.response;
    if (res) {
      //console.log("shes lost yeahhhhhhhhhh", Boolean(res), res.message);
      if (res.status === 404) {
        throw new Error(res.data.error ? res.data.error : `Resource ${res.config.url} not found`);
      } else if (res.status === 400) {
          throw new Error(res.data.error ? res.data.error : `BAD REQUEST  , Check URL parameter`);
      } else if(res.status === 500){
        throw new Error(res.data.error ? res.data.error : `Internal Server Error , Contact Admin `);
      }else if(res.status === 401){
        throw new Error(res.data.error ? res.data.error : `Request not Authorized, Token Expired Try to login again`);
      }
      else {
        throw new Error(error)
      }
    
    } else if (error.request) {
      // The request was made but no response was received
      if (error.code === 'ERR_NETWORK') {
        console.error('Network error');
        if (!navigator.onLine) {
          throw new Error('You are offline. Please check your internet connection.');
        } else {
          throw new Error('Unable to reach the server. Please check the URL or try again later.');
        }
      } else {
        console.error('Unknown error occurred:', error.message);
        throw new Error('An unknown error occurred. Please try again.');
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up the request:', error.message);
      throw new Error('Error in request setup. Please check your request configuration.');
    }
    //setResponse("Error occurred");
  }
};

export const postData = async (url, payload , token) => {
  "";
  //setIsLoading(true);
  try {

    // Prepare headers
    const headers = {};

    // Check if the token is defined and add it to the headers
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(url, {payload},
      {
            headers: headers,
        });
    //console.log('Response data:', response.data);
    return response
    //setResponse(result.data.message);
  } catch (error) {
    console.error(error);
    const res = error.response;
    if (res) {
      //console.log("shes lost yeahhhhhhhhhh", Boolean(res), res.message);
      if (res.status === 404) {
        throw new Error(res.data.error ? res.data.error : `Resource ${res.config.url} not found`);
      } else if (res.status === 400) {
          throw new Error(res.data.error ? res.data.error : `BAD REQUEST  , Check URL parameter`);
      } else if(res.status === 500){
        throw new Error(res.data.error ? res.data.error : `Internal Server Error , Contact Admin `);
      }else if(res.status === 401){
        throw new Error(res.data.error ? res.data.error : `Request not Authorized, Token Expired Try to login again`);
      }
      else {
        throw new Error(error)
      }
    
    } else if (error.request) {
      // The request was made but no response was received
      if (error.code === 'ERR_NETWORK') {
        console.error('Network error');
        if (!navigator.onLine) {
          throw new Error('You are offline. Please check your internet connection.');
        } else {
          throw new Error('Unable to reach the server. Please check the URL or try again later.');
        }
      } else {
        console.error('Unknown error occurred:', error.message);
        throw new Error('An unknown error occurred. Please try again.');
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up the request:', error.message);
      throw new Error('Error in request setup. Please check your request configuration.');
    }
    //setResponse("Error occurred");
  }
};

export const getData = async (url ,params, token  ) => {
  "";
  //setIsLoading(true);
  try {

     // Prepare headers
     const headers = {
      'Content-Type': 'application/json',
     };
    

     // Check if the token is defined and add it to the headers
     if (token) {
         headers['Authorization'] = `Bearer ${token}`;
     }
     

    const response = await axios.get(url ,     
      {
      headers: headers,
      params:params
    });
    //console.log('Response data:', response.data);
    return response
    //setResponse(result.data.message);
  } catch (error) {
    console.error("get data error fro API service",error);
    const res = error.response;
    if (res) {
      //console.log("shes lost yeahhhhhhhhhh", Boolean(res), res.message);
      if (res.status === 404) {
        throw new Error(res.data.error ? res.data.error : `Resource ${res.config.url} not found`);
      } else if (res.status === 400) {
          throw new Error(res.data.error ? res.data.error : `BAD REQUEST  , Check URL parameter`);
      } else if(res.status === 500){
        console.log("error 500" , res)
        throw new Error(res.data.error ? res.data.error : `Internal Server Error , Contact Admin `);
      }else if(res.status === 401){
        throw new Error(res.data.error ? res.data.error : `Request not Authorized, Token Expired Try to login again`);
      }
      else {
        throw new Error(error)
      }
    
    } else if (error.request) {
      // The request was made but no response was received
      if (error.code === 'ERR_NETWORK') {
        console.error('Network error');
        if (!navigator.onLine) {
          throw new Error('You are offline. Please check your internet connection.');
        } else {
          throw new Error('Unable to reach the server. Please check the URL or try again later.');
        }
      } else {
        console.error('Unknown error occurred:', error.message);
        throw new Error('An unknown error occurred. Please try again.');
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up the request:', error.message);
      throw new Error('Error in request setup. Please check your request configuration.');
    }
    //setResponse("Error occurred");
  }
};


