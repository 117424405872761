import { useState, useContext, useEffect } from "react";
import { FiFilter } from "react-icons/fi"; // Import the download icon

import User from "./User";
import EditUserModal from "./EditUserModal";
import DeleteUserModal from "./DeleteUser";
import FilterUsers from "./FilterUsers";

import { getData } from "./APIServices"; // Import your custom methods
import Cookies from "js-cookie";
import LoadingSpinner from "./LoadingSpinner";
import SearchBar from "./SearchBar";
import config from "../config";

const Users = ({ hotspotId }) => {

  const REACT_APP_API_BASE_URL = config.APP_API_BASE_URL;
  
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({}); // Store the selected user
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false); // Control modal visibility
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false); // Control modal visibility
  const [users, setUsers] = useState([]);
  const [isAddFilterUsersModalOpen, setIsAddFilterUsersModalOpen] = useState(false); // Control modal visibility

  const [searchTerm, setSearchTerm] = useState(''); // 



  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [filterSettings, setFilterSettings] = useState({
    isShowUserOnlineOnly: true,
    isShowUserTicketOnly: true,
    isShowUserAccountOnly: true,
    isShowLastTimeConnectOnly: false,
    lastTimeConnectStartDate: getTodayDate(),
    lastTimeConnectEndDate: getTodayDate(),

    // searchTerm:'',
    // isSearchRequest:false
  });
  

  useEffect(() => {
    fetchUsers();
  }, [filterSettings.isShowUserOnlineOnly]);


  const fetchUsers = async (isSearchRequest=false) => {
    
    try {
      setIsLoading(true);
      const searchSettings = {isSearchRequest , searchTerm}
      const token = Cookies.get("token");
      const response = await getData(
        `${REACT_APP_API_BASE_URL}/users/${hotspotId}`,
        { filterSettings ,searchSettings },
        token
      );
      const usersFromServer = response.data;

      if (Array.isArray(usersFromServer) && usersFromServer.length > 0) {
        setUsers(usersFromServer);
        //console.log('users' , users)
      } else {
        //alert("Users est vide")
        setUsers([]);
        return;
      }
      //console.log("userssss from Server" , usersFromServer)
      // if( response.data.hotspotsData){
      //   const hotspotsData = response.data.hotspotsData
      //   setHotspots(hotspotsData)
      //   setAdminID(response.data.adminID)
      // }else{alert('No Hospots in DB')}
      //setVouchersGroups(response.data)
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      setIsLoading(false);
      setSearchTerm("")
    }
  };

  // Handle opening the modal
  //basically what happening when we click edit  , we select user and set to true the modal
  //opening
  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsEditUserModalOpen(true);
  };

  // Handle updating the user data (after edit)
  const handleUpdate = (updatedUser) => {
  
    setIsEditUserModalOpen(false); // Close the modal after updating
  };

  // open delteion modal
  //on delete
  const handleOnDelete = (user) => {
    setSelectedUser(user);
    setIsDeleteUserModalOpen(true);
  };

  const handleDeletion = (user) => {
    console.log("delete user", user.numero);
    //delete from backend
    //delete from UI if we get confirmation from backend
    setIsDeleteUserModalOpen(false); // Close the modal after updating
  };

  const onSearch = () =>{
    fetchUsers(true)
  }

  return (
    <div className="flex flex-col p-4 bg-gray-800 min-h-screen overflow-x-auto">
      {/*sub header here  */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold text-white mb-4">
          Liste des Utilisateurs
        </h1>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={() => setIsAddFilterUsersModalOpen(true)}
        >
          <FiFilter /> {/* Icon for download */}
        </button>
      </div>

      {/* online checkbox  */}
      <div className="flex items-center gap-2">
      <span className="text-white text-sm">Utilisateurs en ligne</span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={filterSettings.isShowUserOnlineOnly}
            onChange={() => setFilterSettings({...filterSettings , isShowUserOnlineOnly:!filterSettings.isShowUserOnlineOnly})}
          />
          <div className="w-11 h-6 bg-gray-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
        </label>
      </div>
      <SearchBar
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          handleSearch = {onSearch}
      />

      {/* can you do toggle for me here its for show user online or not  */}
      {users.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-900 text-white ">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-700">Nom</th>
                <th className="py-2 px-4 border-b border-gray-700">Username</th>
                <th className="py-2 px-4 border-b border-gray-700">Numéro</th>
                <th className="py-2 px-4 border-b border-gray-700">Forfait</th>
                <th className="py-2 px-4 border-b border-gray-700">
                  Status Forfait
                </th>
                {/* <th className="py-2 px-4 border-b border-gray-700">Status</th> */}
                <th className="py-2 px-4 border-b border-gray-700">Category</th>
                <th className="py-2 px-4 border-b border-gray-700">
                  Date de dernière Connection
                </th>
                <th className="py-2 px-4 border-b border-gray-700">
                  Temps Restant
                </th>
                <th className="py-2 px-4 border-b border-gray-700">
                  Traffic Consomme
                </th>
                <th className="py-2 px-4 border-b border-gray-700">Password</th>
                <th className="py-2 px-4 border-b border-gray-700"></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <User
                  key={user.id}
                  user={user}
                  onEdit={handleEdit}
                  onDelete={handleOnDelete}
                />
              ))}
            </tbody>
          </table>
          
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center p-8">
          <div className="text-white text-xl font-semibold mb-2">
            Aucun utilisateur disponible
          </div>
          <p className="text-gray-400 text-center">
            Il n'y a actuellement aucun utilisateur à afficher.
          </p>
        </div>
      )}

      {/* Render EditUserModal if selectedUser is set and modal is open */}
      {isEditUserModalOpen && (
        <EditUserModal
          user={selectedUser}
          onUpdate={handleUpdate}
          setIsEditUserModalOpen={setIsEditUserModalOpen}
        />
      )}

      {/* Render DeleteUserModal if selectedUser is set and modal is open */}
      {isDeleteUserModalOpen && (
        <DeleteUserModal
          user={selectedUser}
          onDelete={handleDeletion}
          setIsDeleteUserModalOpen={setIsDeleteUserModalOpen}
        />
      )}

      {isAddFilterUsersModalOpen && (
        <FilterUsers
          //onDelete={handleDeletion}
          setIsAddFilterUsersModalOpen={setIsAddFilterUsersModalOpen}
          filterSettings={filterSettings}
          setFilterSettings={setFilterSettings}
          fetchUsers={fetchUsers}
        />
      )}
      {/* The loading spinner will overlay the entire screen */}
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default Users;
