import { useEffect, useState, useContext } from "react";
import { Link , useNavigate  } from "react-router-dom"; // Import Link from react-router-dom

import * as Yup from "yup";
import { postData, getData } from "./APIServices"; // Import your custom methods
import LoadingSpinner from "./LoadingSpinner";
import config from "../config";


const Register = () => {

  const REACT_APP_API_BASE_URL = config.APP_API_BASE_URL;
  
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    lastName: "",
    firstName: "",
    phoneNumber: "",
    mail: "",
    city: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "Le numéro de téléphone doit être un nombre")
      .min(10, "Le numéro de téléphone doit contenir au moins 10 chiffres")
      .required("Le numéro de téléphone est requis"),

    password: Yup.string()
      .min(6, "Le mot de passe doit contenir au moins 6 caractères")
      .required("Le mot de passe est requis"),

    lastName: Yup.string()
      .max(50, "Le nom ne doit pas dépasser 50 caractères")
      .required("Le nom est requis"),

    firstName: Yup.string()
      .max(50, "Le prénom ne doit pas dépasser 50 caractères")
      .required("Le prénom est requis"),

    mail: Yup.string()
      .email("L'e-mail n'est pas valide")
      .required("L'e-mail est requis"),

    city: Yup.string().required("La ville est requise"),

  });

  const handleSubmit = async (e) => {
    try {
      setIsLoading(true)
      e.preventDefault();
      console.log(formData);

      await validationSchema.validate(formData, { abortEarly: false });
      // Add your login logic here
      

      const response = await postData(`${REACT_APP_API_BASE_URL}/register` ,
        formData
      );

      console.log("Login submitted:", formData);
      navigate('/login');


      // console.log(response);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        alert(error.errors[0]);
      } else {
        console.error("ici je console l'erreur", error);
        alert(error);
      }
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-black min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md">
        {/* Logo Placeholder */}
        <div className="flex justify-center mb-4">
          <img src="/path/to/your/logo.png" alt="Logo" className="h-12" />
          {/* Replace /path/to/your/logo.png with your logo path */}
        </div>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          Créer un compte utilisateur
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Téléphone:</label>
            <input
              placeholder="Entrer votre numero de Telephone"
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Mot de passe:</label>
            <div className="relative">
              <input
                placeholder="Mot de Pass"
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Cacher" : "Voir"}
              </button>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Nom:</label>
            <input
              placeholder="Entrer Nom"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Prénom:</label>
            <input
              placeholder="Entrer Prenom"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">E-mail:</label>
            <input
              placeholder="Entrer Email"
              type="email"
              name="mail"
              value={formData.mail}
              onChange={handleInputChange}
              className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Ville:</label>
            <input
              placeholder="Entrer votre Ville"
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
            >
              Créer un compte
            </button>
          </div>
        </form>
        {/* Login Prompt */}
        <div className="mt-4 text-center text-gray-600">
          <p>
            Si vous possédez un compte,{" "}
            <Link to="/login" className="text-blue-500 underline">cliquez ici pour vous connecter</Link>
            .
          </p>
        </div>
      </div>
      {/* The loading spinner will overlay the entire screen */}
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default Register;
