import React, { useEffect } from 'react';

const Toast = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000); // Hide after 3 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="fixed top-0 left-0 right-0 flex items-center justify-center z-50">
      <div 
        className={`
          ${type === 'success' ? 'bg-green-500' : 'bg-red-500'}
          text-white px-6 py-3 rounded-lg shadow-lg
          transform transition-all duration-300 ease-in-out
          flex items-center space-x-2 mt-4
          min-w-[300px] max-w-md
        `}
      >
        <div className="flex-1 text-center">{message}</div>
        <button 
          onClick={onClose}
          className="text-white hover:text-gray-200 text-xl font-bold"
        >
          ×
        </button>
      </div>
    </div>
  );
};

export default Toast;