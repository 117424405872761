import React from "react";

const DataPlanActivationHistory = ({ dataPlanActivationHistory }) => {

  return (
    <tr className="hover:bg-gray-700">
      <td className="py-2 px-4 border-b border-gray-700">{dataPlanActivationHistory.username}</td>
      <td className="py-2 px-4 border-b border-gray-700">{dataPlanActivationHistory.category}</td>
     
      <td className="py-2 px-4 border-b border-gray-700">{dataPlanActivationHistory.activationDate}</td>
      
      <td className="py-2 px-4 border-b border-gray-700">
        {dataPlanActivationHistory.expirationDate || "N/A"}
      </td>
      
      <td className="py-2 px-4 border-b border-gray-700">{dataPlanActivationHistory.planCost}</td>
      
      <td className="py-2 px-4 border-b border-gray-700">
        {dataPlanActivationHistory.planVolumeUsed} {dataPlanActivationHistory.planVolumeUsedUnit}
      </td>
      
      <td className="py-2 px-4 border-b border-gray-700">
        {dataPlanActivationHistory.planTimeUsed} {dataPlanActivationHistory.planTimeUsedUnit}
      </td>
      
      <td className="py-2 px-4 border-b border-gray-700">
        {dataPlanActivationHistory.planTotalTime} {dataPlanActivationHistory.planTotalTimeUnit}
      </td>
      
      <td className="py-2 px-4 border-b border-gray-700">
        {dataPlanActivationHistory.planTotalVolume} {dataPlanActivationHistory.planTotalVolumeUnit}
      </td>


     {/* Action Buttons Column */}
      <td className="py-2 px-4 border-b border-gray-700 text-center">
        <div className="inline-block space-y-2">
          {/* Edit Button */}
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded w-full"
            onClick={() => ('')}
          >
            +
          </button>
        </div>
      </td>

    </tr>
  );

};

export default DataPlanActivationHistory;
