import React, { useState, useEffect } from "react";

const DataPlan = ({ dataPlan, onEdit, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <tr className="hover:bg-gray-700 border-b border-gray-700">
      <td className="py-2 px-4">{dataPlan.planName}</td>
      {/*we use event here to manage the lenght of description */}
      <td className="py-2 px-4 max-w-[15rem]">
        <div
          className={`${
            isExpanded ? "h-auto" : "h-20"
          } overflow-hidden relative`}
        >
          <p className={`${isExpanded ? "" : "line-clamp-3"}`}>
            {dataPlan.description}
          </p>
          {!isExpanded && dataPlan.description.length > 100 && (
            <div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-gray-800 to-transparent"></div>
          )}
        </div>
        {dataPlan.description.length > 100 && (
          <button
            className="text-blue-400 hover:text-blue-300 text-sm mt-1"
            onClick={toggleDescription}
          >
            {isExpanded ? "Show less" : "Show more"}
          </button>
        )}
      </td>
      <td className="py-2 px-4">{dataPlan.status}</td>
      <td className="py-2 px-4">{dataPlan.price} {dataPlan.currency}</td>
      <td className="py-2 px-4">{dataPlan.volume === '0' || dataPlan.volume === 0 ? 'Illimite' : `${dataPlan.volume} ${dataPlan.volumeUnit}`}</td>
      {/* <td className="py-2 px-4">{dataPlan.totalTime} {dataPlan.totalTimeUnit}</td> */}
      <td className="py-2 px-4">{dataPlan.totalTimeFormattedDuration}</td>
      {/* <td className="py-2 px-4">{dataPlan.validityPeriod} {dataPlan.validityPeriodUnit}</td> */}
      <td className="py-2 px-4">{dataPlan.validityPeriodFormattedDuration} </td>
      <td className="py-2 px-4">{dataPlan.debitUp}</td>
      <td className="py-2 px-4">{dataPlan.debitDown}</td>
      <td className="py-2 px-4 text-center">
        <div className="inline-block space-y-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded w-full mb-1"
            onClick={() => onEdit(dataPlan)}
          >
            Edit
          </button>
          {/* <button
            className="bg-red-500 text-white px-3 py-1 rounded w-full"
            onClick={() => onDelete(dataPlan)}
          >
            Delete
          </button> */}
        </div>
      </td>
    </tr>
  );
};

export default DataPlan;
