import React from 'react';

const SearchBar = ({ searchTerm, setSearchTerm  ,handleSearch }) => {

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value)
  };
  const onSubmit = (e) => {
    e.preventDefault(); // Prevent form default submission
    handleSearch(); // Call the search function
  };

  return (
    <div className="flex items-center gap-2 mt-4 mb-4">
      <div className="relative flex-1 max-w-md">
        <form onSubmit={onSubmit} className="relative">
          <input
            type="text"
            placeholder="Rechercher username..."
            name="searchTerm"
            value={searchTerm}
            onChange={handleInputChange}
            className="w-full px-4 py-2 text-gray-200 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button 
            type="submit"
            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer hover:text-blue-500"
          >
            <svg
              className="w-5 h-5 text-gray-400 hover:text-blue-500 transition-colors"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchBar;