import { useState, useContext, useEffect } from "react";
import * as Yup from "yup";

import DataPlanForm from "./DataPlanForm";


const EditDataPlan = ({
  selectedDataPlan,setSelectedDataPlan,onUpdate,setIsEditDataPlanModalOpen,
  isLoading,setIsLoading,timeUnits,volumeUnits,validationSchema  , dataPlans , setDataPlans
}) => {
  
 console.log(selectedDataPlan)

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    let inputValue = ''

    if (type === "checkbox"){inputValue = checked}
    else{inputValue = value}

    setSelectedDataPlan((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true)

      // param as createDate and updateDate are not editable so we remove them
      const cleanedDataPlan = { ...selectedDataPlan };
      const fieldsToRemove = [
        'createDate',
        'updateDate',
        'validityPeriodFormattedDuration',
        'totalTimeFormattedDuration'
      ];
    
    fieldsToRemove.forEach(field => delete cleanedDataPlan[field]);

      await validationSchema.validate(cleanedDataPlan, { abortEarly: true });
      // Proceed with form submission or other actions if validation is successful
      const response = await onUpdate(cleanedDataPlan);

      // Update dataPlans state where the ID matches selectedDataPlan's ID (planID)
      setDataPlans((prevDataPlans) =>
        prevDataPlans.map((plan) =>
          plan.planID === selectedDataPlan.planID ? { ...plan, ...selectedDataPlan } : plan
        )
      );
      
      setIsEditDataPlanModalOpen(false)

    } catch (error) {
      if (error instanceof Yup.ValidationError) {alert(error.errors[0])}
      else{
        alert(error)
      }
      console.error("error here",error)
    }finally{
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* Button to open modal */}

      {/* Modal */}
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto p-4 ">
        <div className="bg-white rounded-lg w-full max-w-md p-6 relative max-h-[90vh] overflow-y-auto">
          {/* Close Button */}
          <button
            className="absolute text-2xl top-8 right-2 text-red-600 hover:text-gray-900"
            onClick={() => setIsEditDataPlanModalOpen(false)}
          >
            X
          </button>

          {/* Form Title */}
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            Modifier Forfait
          </h2>

          {/* Add Form */}
          {/* Add Form */}
          <DataPlanForm
            dataPlan={selectedDataPlan}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            timeUnits = {timeUnits}
            volumeUnits = {volumeUnits}
          />
        </div>
      </div>
    </div>
  );
};

export default EditDataPlan;
