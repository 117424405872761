import { useState, useContext , useEffect} from "react";

import DataPlan from "./DataPlan";
import AddDataPlan from "./AddDataPlan";
import EditDataPlan from "./EditDataPlan";
import { postData, getData , putData } from "./APIServices"; // Import your custom methods

import axios from "axios";
import * as Yup from "yup";
import Cookies from 'js-cookie';
import LoadingSpinner from "./LoadingSpinner";
import config from "../config";



import { boolean } from "yup";

const DataPlans = ({volumeUnits , timeUnits , hotspotId}) => {

  const REACT_APP_API_BASE_URL = config.APP_API_BASE_URL;

  const [dataPlans, setDataPlans] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isAddDataPlanModalOpen, setIsAddDataPlanModalOpen] = useState(false); // Control modal visibility
  const [isEditDataPlanModalOpen, setIsEditDataPlanModalOpen] = useState(false); // Control modal visibility
  const [selectedDataPlan, setSelectedDataPlan] = useState({}); // Store the selected user

  const [response, setResponse] = useState("");

  useEffect(() => {
    // Simulating fetching hotspots from an API
    setIsLoading(true)
    const token = Cookies.get('token');

    const fetchDataPlans = async () => {
      try {
        const response = await getData(
          `${REACT_APP_API_BASE_URL}/plans/${hotspotId}` ,{}, token
        );
        //console.log("response data",response.data);
        //console.log("type of",typeof(response.data));
        if (Array.isArray(response.data) && response.data.length > 0) {
          //console.log("we are in, data received");
          setDataPlans(response.data);
        } else {
          alert("Aucun Plan à Afficher pour ce Hospot");
        }
        return
        //const hotspotsData = response.data.hotspotsData
        //setHotspots(hotspotsData)
        //setVouchersGroups(response.data)
      } catch (error) {
        console.error(error);
        alert(error);
      }finally {
        setIsLoading(false);
      }
    };

    fetchDataPlans();

  }, []);

  const EmptyStringToUndefined = (value, originalValue) => 
    String(originalValue).trim() === '' ? undefined : value

  const validationSchema = Yup.object().shape({
    planName: Yup.string()
      .max(17, "Nom du forfait doit pas depasser 17 Caracteres")
      .required("nom forfait est vide"),

    description: Yup.string()
      .max(240, "description doit pas depasser 245 Caracteres"),
    
    status: Yup.boolean()
    .required("Le statut actif/inactif est requis") , 

    price: Yup.number("Le prix doit etre un nombre").integer(
      "La période de validité doit être un nombre entier").
    required(
      "Le prix est requis"
    ),

    // discount: Yup.number("La Reduction doit etre un nombre").
    // required(
    //   "La Reduction est requis"
    // ),

    volume: Yup.number("le volume doit etre un nombre").transform(
      EmptyStringToUndefined
    ),

    validityPeriod: Yup.number(
      "periode de Validite doit etre un nombre"
    ).integer("La période de validité doit être un nombre entier").transform(
      EmptyStringToUndefined
    ).required("periode de validite est requis"),

    totalTime: Yup.number(
      "Duree de illimite doit etre un nombre"
    ).required("Duree de illimite est requis"),

    debitUp: Yup.number("DebitUp est vide").transform(
      EmptyStringToUndefined
    ).required("DebitUp est requis"),

    debitDown: Yup.number("DebitDown est vide").transform(
      EmptyStringToUndefined
    ).required("DebitDown est requis"),
  });

  // Handle opening the modal
  //basically what happening when we click edit  , we select user and set to true the modal
  //opening
  const handleEdit = (dataPlan) => {
    setSelectedDataPlan(dataPlan);
    setIsEditDataPlanModalOpen(true);
  };

  
  const addDataPlan = async (dataPlan) => {
    ''
    const token = Cookies.get('token');

    const response = await postData(
      `${REACT_APP_API_BASE_URL}/plan/${hotspotId}`,dataPlan,token
    );
    console.log(response.data);
    return response.data
  };

  const updateDataPlan = async (dataPlan) => {
    ''
    const token = Cookies.get('token');
    console.log("here we print")
    const response = await putData(
      `${REACT_APP_API_BASE_URL}/plan/${hotspotId}`,dataPlan,token
    );
    //console.log(response.data);
    return response.data
  };



  return (
    <div className="flex flex-col p-4   min-h-screen overflow-x-auto">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold text-white mb-4">Forfaits</h1>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={() => setIsAddDataPlanModalOpen(true)}
        >
          Add
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-900 text-white ">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-700">
                Nom Forfait
              </th>
              <th className="py-2 px-4 border-b border-gray-700">
                Description
              </th>
              <th className="py-2 px-4 border-b border-gray-700">
                Status
              </th>
              <th className="py-2 px-4 border-b border-gray-700">Prix</th>
              <th className="py-2 px-4 border-b border-gray-700">Volume</th>
              <th className="py-2 px-4 border-b border-gray-700">
                Temps de connection
              </th>
              <th className="py-2 px-4 border-b border-gray-700">
                Periode de Validite
              </th>
              <th className="py-2 px-4 border-b border-gray-700">Debit Up / Mpbs</th>
              <th className="py-2 px-4 border-b border-gray-700">Debit Down / Mpbs</th>
            </tr>
          </thead>
          <tbody>
            {dataPlans.map((dataPlan) => (
              <DataPlan
                key={dataPlan.planID}
                dataPlan={dataPlan}
                onEdit={handleEdit}
              />
            ))}
          </tbody>
        </table>
      </div>

      {isAddDataPlanModalOpen && (
        <AddDataPlan
          //onDelete={handleDeletion}
          setIsAddDataPlanModalOpen={setIsAddDataPlanModalOpen}
          addDataPlan={addDataPlan}
          setDataPlans={setDataPlans}
          dataPlans = {dataPlans}
          isLoading={isLoading}
          setIsLoading = {setIsLoading}
          timeUnits = {timeUnits}
          volumeUnits={volumeUnits}
          validationSchema = {validationSchema}
        />
      )}

      {isEditDataPlanModalOpen && (
        <EditDataPlan
        selectedDataPlan={selectedDataPlan}
        setSelectedDataPlan={setSelectedDataPlan}
          //onDelete={handleDeletion}
          setDataPlans={setDataPlans}
          dataPlans = {dataPlans}
          isLoading={isLoading}
          onUpdate = {updateDataPlan}
          setIsLoading = {setIsLoading}
          setIsEditDataPlanModalOpen={setIsEditDataPlanModalOpen}
          timeUnits = {timeUnits}
          volumeUnits={volumeUnits}
          validationSchema = {validationSchema}
        />
      )}

      {isLoading && <LoadingSpinner />}

    </div>
  );
};

export default DataPlans;