import React from "react";
import { useState, useContext } from "react";
import DataPlanForm from "./DataPlanForm";
import * as Yup from "yup";


const AddDataPlan = ({ setIsAddDataPlanModalOpen, addDataPlan, setDataPlans,dataPlans,
  isLoading,setIsLoading,timeUnits,volumeUnits,validationSchema  }) => {
 

  const [dataPlan, setDataPlan] = useState({
    planName: "",
    description: "",
    price: 100,
    status:1,
    discount:0,
    //make sure first opt is GB
    volumeUnit: volumeUnits[0], // Default to the first option dynamically
    volume: 1,
    //make sure first opt is days
    validityPeriodUnit: timeUnits[0],
    validityPeriod: 30,
    totalTime:0,
    totalTimeUnit: timeUnits[0],
    //unit mpbs
    debitUp: 1,
    debitDown: 1,
  });

  

  const handleInputChange = (e) => {
    const { name, type, value, checked } = e.target;
  
    let inputValue = ''

    if (type === "checkbox"){inputValue = checked}
    else{inputValue = value}

    setDataPlan((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
    //console.log(dataPlan)
  };


  const handleSubmit = async (e) => {
    
    e.preventDefault();
    
    try {
      setIsLoading(true)
      await validationSchema.validate(dataPlan, { abortEarly: false });
      // If validation passes, proceed with submission
      const response = await addDataPlan(dataPlan);
      const planID = response.id
      console.log(response)
      setIsAddDataPlanModalOpen(false)
      setDataPlans([{...dataPlan , planID:planID} ,...dataPlans ])
    } catch  (error) {
      if (error instanceof Yup.ValidationError) {alert(error.errors[0])}
      else{
        alert(error)
      }
      console.error("error here",error)
    }finally {
      setIsLoading(false);
    }
    //you can first do all checking here and alert them
    // console.log("Valider")
    console.log(dataPlan);
    //onUpdate(formData);
  };


  return (
    <div>
      {/* Modal */}
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto p-4">
        <div className="bg-white rounded-lg w-full max-w-md p-6 relative max-h-[90vh] overflow-y-auto">
          {/* Close Button */}
          <button
            className="absolute text-2xl top-8 right-2 text-red-600 hover:text-gray-900"
            onClick={() => setIsAddDataPlanModalOpen(false)}
            disabled={isLoading}
          >
            X
          </button>

          {/* Form Title */}
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            Ajouter Forfait
          </h2>

          {/* Add Form */}
          <DataPlanForm
            dataPlan={dataPlan}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            timeUnits = {timeUnits}
            volumeUnits = {volumeUnits}
          />
        </div>
      </div>
    </div>
  );
};

export default AddDataPlan;
